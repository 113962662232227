export default {
  text: '#453e35',
  primary: '#853827',
  secondary: '#e5a253',
  tertiary: '#83a394',
  maroon: '#883c2a',
  background: '#fbfbf0',
  backgroundSecondary: '#fff',
  light: '#FFF',
  dark: '#202020'
}
