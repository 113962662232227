export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    padding: '0.5rem',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'secondary'
    }
  },
  secondary: {
    color: 'tertiary',
    padding: '0rem',
    border: 'none',
    borderBottom: '2px solid',
    width: 'fit-content',
    textAlign: 'center',
    margin: '1rem auto 0rem',
    backgroundColor: 'transparent',
    ':hover': {
      color: 'primary',
      backgroundColor: 'transparent'
    }
  }
}
